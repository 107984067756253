import React, { useState, useEffect } from 'react';
import { Row, Col, Space, Input, Card, Typography } from 'antd';
const { TextArea } = Input;
import { BulbOutlined } from '@ant-design/icons';
const { Text } = Typography;

const TextAreaWithLanguage = ({
  language, 
  defaultValue, 
  onBlur, 
  minRows, 
  maxRows,
  translations,
  primary=false,
  placeholder = ""
}) => {
  const [onFocusValue, setOnFocusValue] = useState(null);
  return (
    <Space direction='vertical' size={0} style={{width: "100%"}}>
      <TextArea
        defaultValue={defaultValue}
        placeholder={placeholder}
        style={{
          borderBottomLeftRadius:"0px",
          borderBottomRightRadius:"0px",
        }}
        autoSize={{ 
          minRows,
          maxRows
        }}
        onFocus={(e) => {setOnFocusValue(e.target.value)}}
        onBlur={async (e) => {
          if (onFocusValue !== e.target.value) {
            onBlur(e.target.value);
            setOnFocusValue(null);
          }
        }}
      />
      <div style={{ 
        textAlign: 'center', 
        backgroundColor: primary ? '#d9d9d9' : '#f5f5f5',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottomLeftRadius: "5px",
        border: "1px solid #d9d9d9",
        borderColor: "#d9d9d9",
        borderBottomRightRadius:"5px",
        fontStyle: primary ? '' : 'italic',
      }}>
        {`${language}${primary ? '' : (' (' + translations?.optional + ")")}`}
      </div>
    </Space>
  )
}

const TranslationFormItem = ({
  value, 
  onChange, 
  nativeLanguage, 
  minRows = 1, 
  maxRows = minRows, 
  translations,
  placeholderTranslationKey,
  autoSave,
  columnName,
  hideExamples
}) => {
  const enValue = value?.en || "";
  const nativeValue = value?.[nativeLanguage] || "";
  const [currentValue, setCurrentValue] = useState({
    en: enValue,
    ...nativeLanguage !== "en" && { [nativeLanguage]: nativeValue }
  });

  useEffect(() => {
    onChange(currentValue);
  }, [])

  const languageKeyMapping = {
    de: translations?.german,
    en: translations?.english,
    it: translations?.italian,
    fr: translations?.french,
    es: translations?.spanish
  }

  const handleBlur = (language, value) => {
    const newValue = {...currentValue, [language]: value};
    onChange(newValue);
    autoSave({
      columnName,
      value: newValue
    })
    setCurrentValue(newValue);
  }

  return (
    <Row style={{ width: '100%' }} gutter={[8, 0]}>
      <Col span={!hideExamples ? 9 : 12}>
        <TextAreaWithLanguage
          language={languageKeyMapping[nativeLanguage]}
          defaultValue={nativeValue}
          placeholder=""//{translations?.[placeholderTranslationKey] || ("translations?." + placeholderTranslationKey)}
          minRows={minRows}
          maxRows={maxRows}
          primary
          translations={translations}
          onBlur={(value) => {
            handleBlur(nativeLanguage, value);
          }}
        />
      </Col>
      <Col span={!hideExamples ? 9 : 12}>
        {nativeLanguage !== "en" && 
          <TextAreaWithLanguage
            language={languageKeyMapping["en"]}
            defaultValue={enValue}
            minRows={minRows}
            maxRows={maxRows}
            translations={translations}
            onBlur={(value) => {
              handleBlur("en", value);
            }}
          />
        }
      </Col>
      { !hideExamples &&
        <Col span={6}>
          {translations?.[placeholderTranslationKey]  && 
            <Card size="small" title={<><BulbOutlined color="#9b9b9b"/> {translations?.examples || "translations?.examples"}</>} style={{ width: "100%", height: "100%" }}>
              <Text italic>{translations?.[placeholderTranslationKey]}</Text>
            </Card>
          }
        </Col>
      }
    </Row>
  )
}

export default TranslationFormItem;