import React from 'react';
import { Alert } from 'antd';

const SuccessMessage = ({translations}) => {
  return (
    <Alert
      style={{margin: '20px 20px 0px 20px'}}
      message={translations?.submitSuccess}
      description={translations?.confirmation}
      type="success"
      showIcon
    />
  );
};

export default SuccessMessage;
