import React, { useState } from 'react';
import axios from 'axios';
import endpoints from '../utils/endpoints';
import LanguagePicker from './LanguagePicker';
import { LoginOutlined } from '@ant-design/icons';
import { Card, Form, Flex, Space, Input, Alert, Typography, Button } from 'antd';
const { Title, Text } = Typography;

const LoginForm = ({
  uuid,
  translations,
  noTouch,
  editMode,
  setSessionIsAuthed,
  setPlace,
  setSubmission,
  translationLanguage,
  setTranslationLanguage,
  urlPasscode
}) => {
  const [passcode, setPasscode] = useState(urlPasscode || '');
  const [authError, setAuthError] = useState(false);

  const handleAuthError = (logMessage) => {
    setAuthError(true);
    setPasscode('');
  };

  const handleSubmit = () => {
    const authParams = {
      uuid: uuid,
      passcode
    };
    axios.post(endpoints.authRequest, authParams, {
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        noTouch,
        editMode
      }
    })
    .then(response => response.data)
    .then(data => {
      if (!data.success) {
        handleAuthError(data.error);
        return;
      } else {
        //TODO: Add logic to handle already submitted submissions
        setSessionIsAuthed(true);
        setPlace(data.place);
        setSubmission(data.submission)
      }
    })
    .catch(error => handleAuthError(error.message));
  };

  const handlePasscodeChange = (e) => {
    setPasscode(e.target.value);
    if (authError) setAuthError(false);
  };

  const actions = [
      <Button 
        style={{width: "calc(100% - 30px)"}} 
        type="primary" 
        onClick={handleSubmit} 
        icon={<LoginOutlined/>}
        disabled={passcode.length < 6}
      >
        {translations?.login}
      </Button>
  ];

  return (
    <Flex justify="center" align="center" vertical>
      <Card
        style={{ 
          width: 500,
          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
        }}
        title={
          <div style={{ textAlign: 'center' }}>
            <Title level={2}>
              {translations?.formWelcome}
            </Title>
          </div>
        }
        actions={actions}
      >
        <Form
          labelCol={{ span: 12 }}
        >
          <Form.Item htmlFor="password">
            <Flex vertical align="center" gap={10}>
              <Text strong>{translations?.enterPasscode}</Text>
              <Input.Password
                style={{ width: 150 }}
                id="password"
                defaultValue='HG7MEM'
                value={passcode}
                onChange={handlePasscodeChange}
                onPressEnter={() => {
                  if (passcode.length > 5) {
                    handleSubmit();
                  }
                }}
                autoFocus // Add this line to autofocus the input on page load
              />
            </Flex>
          </Form.Item>
        </Form>
        {authError && <Alert message={translations?.loginError} type="warning" showIcon />}
      </Card>
      <LanguagePicker
        translationLanguage={translationLanguage}
        setTranslationLanguage={setTranslationLanguage}
      />
    </Flex>
  );
};

export default LoginForm;