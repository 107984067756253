import React from "react";
import { Card } from "antd";

const FormSection = ({title, children, isImportant = false}) => {
  const importantStyle = isImportant ? {border: "1px solid black"} : {};
  return (
    <Card 
      size="default"
      title={<h2 style={{ textAlign: 'center' }}>{title}</h2>}
      header={{backgroundColor: "black"}}
      style={{
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
        marginBottom: "20px",
        ...importantStyle,
      }}
    >
      {children}
    </Card>
  );
}
export default FormSection;