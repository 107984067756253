const BACKEND_HOST = process.env.BACKEND_HOST;

export default {
  autoSave: `${BACKEND_HOST}/autosave`,
  submit: `${BACKEND_HOST}/submit`,
  translations: `${BACKEND_HOST}/translations`,
  authRequest: `${BACKEND_HOST}/auth-request`,
  formTranslations: `${BACKEND_HOST}/form-translations`,
  imageUpload: `${BACKEND_HOST}/img-upload`,
  imageDelete: `${BACKEND_HOST}/img-delete`,
  getImages: `${BACKEND_HOST}/place-images`,
  getImage: `${BACKEND_HOST}/place-image`,
  revision: `${BACKEND_HOST}/revision`,
  selectOptions: `${BACKEND_HOST}/select-options`,
  translation: `${BACKEND_HOST}/translation`,
  submission: `${BACKEND_HOST}/submission`,
  place: `${BACKEND_HOST}/place`,
  publication: `${BACKEND_HOST}/publication`,
}