import React from "react";
import { Form, Button, Flex, Alert } from "antd";

const SubmitButton = ({ 
  form, 
  children, 
  messageApi, 
  submitting,
  translations,
  onFinish
}) => {
  const values = Form.useWatch([], form);
  const [submittable, setSubmittable] = React.useState(false);
  const [showSubmitClickError, setShowSubmitClickError] = React.useState(false);
  const handleClick = async () => {
    if (submittable) {
      onFinish(values)
    } else {
      setShowSubmitClickError(true);
    }
  }
  // Watch all values
  React.useEffect(() => {
    setShowSubmitClickError(false);
    form
      .validateFields({
        validateOnly: true,
      })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);
  return (
    <Flex vertical gap={8}>
      {showSubmitClickError && <Alert icon type="warning" message={translations?.formSubmitError} />}
      <Button block size="large" type="primary" onClick={handleClick} loading={submitting}>
        {children}
      </Button>
    </Flex>
  );
};

export default SubmitButton;