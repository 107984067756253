import { Radio, Flex } from 'antd';
import React from 'react';

const LanguagePicker = ({
  translationLanguage,
  setTranslationLanguage
}) => {
  return (
    <Flex 
      justify='center'
      style={{marginTop: "20px"}}
    >
      <Radio.Group
        defaultValue={translationLanguage}
        onChange={(e) => setTranslationLanguage(e.target.value)}
      >
        <Radio.Button value="de">🇩🇪 Deutsch</Radio.Button>
        <Radio.Button value="en">🇺🇸 English</Radio.Button>
        <Radio.Button value="es">🇪🇸 Español</Radio.Button>
        <Radio.Button value="fr">🇫🇷 Français</Radio.Button>
        <Radio.Button value="it">🇮🇹 Italiano</Radio.Button>
      </Radio.Group>
    </Flex>
  );
};
export default LanguagePicker;