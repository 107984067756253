import React, { useMemo, useEffect, useState } from "react";
import axios from "axios";
import { useLocation} from "react-router-dom";

import { Layout } from "antd";
import { Header, Content } from "antd/es/layout/layout.js";

import LoginForm from "./components/LoginForm.jsx";
import Form from "./components/Form.jsx";
import SuccessMessage from "./components/SuccessMessage.jsx";

import {isEmpty} from "lodash";
import endpoints from "./utils/endpoints.js";
import logo from"./assets/logo.png";
import SubmissionControlForm from "./components/SubmissionControlForm.jsx";

const App = () => {
  /* const initialSubmission = process.env.NODE_ENV === 'development' 
  ? { updated_fields: { 
    name: 'new', 
    working_guest_conditions: "food_and_shelter",
    self_description: {"en": "en sd", "it": "italiano"}
  } } 
  : {}; 
  
  const initialPlace = process.env.NODE_ENV === 'development'
  ? { name: "old", self_description: {en: "en sd", de: "de sd"}, is_ecovillage: false, keyword_ids:[1,2,3]}
  : {};
  */

  const initialSubmission = {}
  const initialPlace = {}

  const initialSessionIsAuthed = /* process.env.NODE_ENV === 'development' ? true : */ false;

  const [translations, setTranslations] = useState(null);
  const [sessionIsAuthed, setSessionIsAuthed] = useState(initialSessionIsAuthed);
  const [place, setPlace] = useState(initialPlace)
  const [submission, setSubmission] = useState(initialSubmission)
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const location = useLocation();
  const uuid = new URLSearchParams(location.search).get("uuid");
  const noTouchParam = new URLSearchParams(location.search).get("noTouch");
  const noTouch = noTouchParam === "true" ? true : false;
  const editModeParam = new URLSearchParams(location.search).get("editMode");
  const editMode = editModeParam === "true" ? true : false;

  const [translationLanguage, setTranslationLanguage] = useState(
    ["de", "en", "es", "fr", "it"].includes(navigator.language) ? navigator.language : "en"
  );

  useEffect(() => {
    if (submitSuccess) {
      window.scrollTo(0, 0);
    }
  }, [submitSuccess]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(endpoints.translations + "?languageCode=" + translationLanguage, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        setTranslations(response.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchData();
  }, [translationLanguage]);

  if (!uuid || !/^[a-f\d]{8}-([a-f\d]{4}-){3}[a-f\d]{12}$/i.test(uuid)) {
    window.location.href = "https://eurotopia.de";
    return null;
  }
  
  const memoizedTranslations = useMemo(() => translations, [translations]);
  
  return (
    <Layout style={{minHeight:"100vw"}}>
      <Header style={{ 
        backgroundColor: "white", 
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
        marginBottom: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        <img
          height={"50%"}
          src={logo}
          alt="eurotopia logo"
        />
      </Header>
      <Content>
        {!sessionIsAuthed && <LoginForm 
          uuid={uuid} 
          noTouch={noTouch}
          editMode={editMode}
          setSessionIsAuthed={setSessionIsAuthed} 
          setPlace={setPlace}
          setSubmission={setSubmission}
          translations={memoizedTranslations} 
          translationLanguage={translationLanguage}
          setTranslationLanguage={setTranslationLanguage}
          urlPasscode={new URLSearchParams(location.search)?.get("passcode") || ""}
        />
        }
        {(sessionIsAuthed && !submitSuccess && !isEmpty(place) && !isEmpty(submission)) && !editMode &&
          <Form 
            uuid={uuid} 
            translations={memoizedTranslations} 
            place={place}
            submission={submission}
            translationLanguage={translationLanguage}
            setSubmitSuccess={setSubmitSuccess}
          />
        }
        {(sessionIsAuthed && !submitSuccess && !isEmpty(place) && !isEmpty(submission)) && editMode &&
          <SubmissionControlForm place={place} submission={submission}/>
        }
        {
          submitSuccess && <SuccessMessage translations={memoizedTranslations} />
        }
      </Content>
    </Layout>
  );
};

export default App;
