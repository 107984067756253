import React, { useState, useEffect } from 'react';
import { Flex, Button, Row, Col, Space, Divider, Typography, Input, Alert, Popover } from 'antd';
const { Text } = Typography;
import endpoints from '../../utils/endpoints';
const axios = require('axios');
const { TextArea } = Input;
import { 
  DatabaseOutlined, 
  LoadingOutlined,
  CopyTwoTone,
  DiffOutlined, 
  EditTwoTone, 
  TranslationOutlined, 
  CheckCircleTwoTone,
  ExclamationCircleTwoTone
} from '@ant-design/icons';

const languageIcons = {
  'de': "🇩🇪",
  'en': "🇬🇧",
  'fr': "🇫🇷",
  'es': "🇪🇸",
  'it': "🇮🇹",
}

const revisionStatuses = {
  CLEAR: "clear",
  SYNCED: "synced",
  NEEDS_UPDATE: "needsUpdate"
}

const TranslationIconBar = ({translationText, sourceLang, note}) => {
  const [translationLoading, setTranslationLoading] = useState(false)
  const [showTranslationSuccessIcon, setShowTranslationSuccessIcon] = useState(false)
  const availableLangs = _.keys(languageIcons).filter(lang => lang !== sourceLang)
  const getTranslation = async(text, sourceLang, targetLang) => {
    setTranslationLoading(true)
    try {
      const response = await axios.post(endpoints.translation, {
        text,
        sourceLang,
        targetLang
      })
      navigator.clipboard.writeText(response.data.text);
      setTranslationLoading(false)
      setShowTranslationSuccessIcon(true)
      setTimeout(async () => {
        setShowTranslationSuccessIcon(false)
      }, 2000)
    } catch (error) {
      console.log(error)
    } 
  }
  const iconContainerProps = {
    style: {
      padding: "2px", 
      borderLeft: "1px", 
      borderRight: "1px", 
      borderTop: "0px", 
      borderBottom: "1px", 
      borderColor:"#dcdcdc", 
      borderStyle:"solid", 
      borderRadius: "0px 0px 5px 5px",
      visibility: translationText ? "visible" : "hidden",
    },
  }

  return (
    <Flex justify='flex-end' gap={8}>
      {
        note && (
          <Text style={{fontSize: 14}} strong type="secondary">{note}</Text>
        )
      }
      <Popover content={
        <Space.Compact direction="horizontal">
          {
            availableLangs.map((lang, index) => {
              return (
                <Button
                key={index}
                onClick={async () => {
                  await getTranslation(translationText, sourceLang, lang)
                  }}
                >
                  {languageIcons[lang]}
                </Button>
              )
            })
          }
        </Space.Compact>}
      >
        <div {...iconContainerProps}>
          {
            translationLoading && (
              <LoadingOutlined />
            )
          }
          {
            !showTranslationSuccessIcon && !translationLoading &&  (
              <TranslationOutlined />  
            )
          }
          {
            showTranslationSuccessIcon && (
              <CopyTwoTone twoToneColor={"#60D394"}/>
            )
          }
        </div>
      </Popover>
    </Flex>
  )
}
const TranslationTextControl = ({textAreaComp, translationText, sourceLang, allLangs, note}) => {
  return (
    <Flex vertical style={{width:"100%", height:"100%"}}>
      {textAreaComp}
      <TranslationIconBar
        translationText={translationText} 
        sourceLang={sourceLang} 
        allLangs={allLangs}
        note={note}
      />
    </Flex>
  )
}

const TranslationSourceIndicator = ({icon, color}) => {
  const style = {
    background: color ? color : "#F5F5F5", 
    padding: "4px",
    borderRadius:"3px",
    width: "100%",
    align: "center",
  }
  return (
    <Flex justify='center' align='center' style={style}>
      {icon}
    </Flex>
  )

}

const TranslationStatusIcon = ({status}) => {
  switch (status) {
    case revisionStatuses.CLEAR:
      return <></>
    case revisionStatuses.SYNCED:
      return <CheckCircleTwoTone twoToneColor={"#60D394"}/>
    case revisionStatuses.NEEDS_UPDATE:
      return <ExclamationCircleTwoTone twoToneColor="#EE6055"/>
    default:
      return <Text>{status}</Text>
  }
}

const TanslationFieldControl = ({
  uuid,
  placeId,
  colName,
  colLabel,
  _originalValue,
  _updatedValue,
  _revisedValue,
  langs,
  extra
}) => {
  const [updatedValue, setUpdatedValue] = useState(_updatedValue)
  const [revisedValue, setRevisedValue] = useState(_revisedValue)
  const [originalValue, setOriginalValue] = useState(_originalValue)
  const [currentRevision, setCurrentRevision] = useState(revisedValue ?? updatedValue ?? {})
  const [revisionStatus, setRevisionStatus] = useState(null)
  const [submissionLoading, setSubmissionLoading] = useState(false)
  const [revisionStatusColor, setRevisionStatusColor] = useState(null)
  
  useEffect(() => {
    const getStatus = () => {
      const hasUpdated = !_.isEmpty(updatedValue)
      const hasRevised = !_.isEmpty(revisedValue)
      const hasCurrentRevision = !_.isEmpty(currentRevision)
      const userHasMadeChanges = !_.isEqual(revisedValue, currentRevision)
      const isSynced = !_.isEmpty(revisedValue) && _.isEqual(revisedValue, currentRevision)
      
      if (!hasUpdated && !hasRevised && !hasCurrentRevision) {
        setRevisionStatusColor(null)
        return revisionStatuses.CLEAR
      }
      if (userHasMadeChanges) {
        setRevisionStatusColor("#EE6055")
        return revisionStatuses.NEEDS_UPDATE
      }
      if (isSynced) {
        setRevisionStatusColor("#60D394")
        return revisionStatuses.SYNCED
      }
      return "error"
    }
    let status = getStatus()
    
    setRevisionStatus(status)
  }, [currentRevision, revisedValue])

  const submitRevision = async () => {
    setSubmissionLoading(true)
    try {
      const response = await axios.post(endpoints.revision, {
        uuid,
        placeId,
        columnName: colName,
        value: currentRevision,
      })
      if (response.data.success) {
        setRevisedValue(currentRevision)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setSubmissionLoading(false)
    }
  }

  return (
    <Flex vertical gap={8}>
      <Row style={{width: "100%"}}>
        <Divider> {colLabel} <TranslationStatusIcon status={revisionStatus}/> </Divider>
      </Row>
      <Row gutter={16} style={{width: "100%"}} wrap={false}>
        <Col flex={"38px"} />
        <Col flex={1}>
          <TranslationSourceIndicator icon={<DatabaseOutlined/>}/>
        </Col>
        <Col flex={1}>
          <TranslationSourceIndicator icon={<DiffOutlined/>}/>
        </Col>
        <Col flex={1}>
          <TranslationSourceIndicator
            color={revisionStatusColor && revisionStatusColor + "33"}
            icon={<EditTwoTone twoToneColor={revisionStatusColor || "#333333"}/>}
          />
        </Col>
      </Row>
      {
        langs.map((lang, index) => {
          return (
            <Row key={index} gutter={16} style={{width: "100%"}} wrap={false}>
              <Col flex={"38px"}>
                <Flex style={{
                    height:"calc(100% - 29px)",
                    borderRadius: "3px",
                    marginRight: "-10px",
                    background: '#F5F5F5',
                    border: "1px solid #f0f0f0",
                  }} 
                  justify='center' 
                  align='center'
                >
                  <Text style={{padding: "2px"}}>{languageIcons[lang]}</Text>
                </Flex>
              </Col>
              <Col flex={1}>
                <TranslationTextControl
                  textAreaComp={
                    <TextArea
                      style={{
                        height: "100%",
                        cursor: "default"
                      }}
                      value={originalValue?.[lang]}
                      readOnly
                    />
                  }
                  translationText={originalValue?.[lang]}
                  sourcelang={lang}
                  allLangs={langs}
                  note={colName==="self_description" && extra}
                />
              </Col>
              <Col flex={1}>
              <TranslationTextControl
                  textAreaComp={
                    <TextArea
                      style={{
                        height: "100%",
                        cursor: "default"
                      }}
                      value={updatedValue?.[lang]}
                      readOnly
                    />
                  }
                  translationText={updatedValue?.[lang]}
                  sourceLang={lang}
                  allLangs={langs}
                />
              </Col>
              <Col flex={1}>
              <TranslationTextControl
                  textAreaComp={
                    <TextArea
                      style={{
                        height: "100%",
                        ...revisionStatusColor ? {border: `1px solid ${revisionStatusColor}`} : {}
                      }}
                      defaultValue={currentRevision?.[lang]}
                      onChange={(e) => {
                        if (!e.target.value) {
                          let rev = {...currentRevision}
                          delete rev[lang]
                          setCurrentRevision({...rev})
                        } else {
                          setCurrentRevision({...currentRevision, [lang]: e.target.value})
                        }
                      }}
                    />
                  }
                  translationText={currentRevision?.[lang]}
                  sourceLang={lang}
                  allLangs={langs}
                />
              </Col>
            </Row>
          )
        })
      }
      {
        revisionStatus === revisionStatuses.NEEDS_UPDATE && (
          <Row gutter={16} style={{marginRight: "38px", width: "100%"}}>
            <Col span={8}/>
            <Col span={8}/>
            <Col style={{paddingLeft:"20px"}} span={8}>
              <Button
                loading={submissionLoading}
                onClick={async () => {
                  await submitRevision()
                }}
                icon={<ExclamationCircleTwoTone twoToneColor="#EE6055"/>}
                style={{width: "100%", background: "#FDEEED", borderColor: "#EE6055"}}
              >
                Änderungen speichern
              </Button>
            </Col>
          </Row>
        )
      }
      </Flex>
  )
}

export default TanslationFieldControl;